<template>
  <div :class="large ? 'flex-1 p-5 rounded-md shadow-lg bg-Neutral-50 hover:bg-Primary-50 focus:bg-Primary-50 cursor-pointer' : ''">
    <h3 v-if="heading" class="text-2xl leading-7 mb-4 text-black ">
      {{ heading }}
    </h3>
    <HeadlessCombobox
      nullable
      as="div"
      v-model="selectedOption"
      v-slot="{ open }"
      @update:model-value="$emit('update:model-value', selectedOption)"
    >
      <div class="relative mt-1 w-full">
        <HeadlessComboboxButton
          class="flex items-center rounded-r-md px-2 focus:outline-none w-full flex items-center cursor-pointer"
        >
          <HeadlessComboboxInput
            class="w-full border-0 bg-white pl-3 pr-10 shadow-sm ring-1 ring-inset focus:ring-1 focus:ring-inset focus:ring-Primary-800"
            :class="{
              'text-lg leading-6 py-4 ring-Neutral-400': large,
              'text-base  sm:text-sm leading-6 py-1.5 ring-Primary-800': !large,
              'text-Neutral-400': large && !open && !selectedOption,
              'text-gray-900': !(large && !open && !selectedOption),
            }"
            @change="selectedOptionInputQuery = $event.target.value"
            :placeholder="props.caption"
            :display-value="
              (scenario) => scenario == null
                  ? ''
                  : scenario[props.labelField]
            "
          />
          <span class="absolute right-2">
            <ChevronDownIcon
              class="h-5 w-5 mx-2 text-gray-900"
              aria-hidden="true"
            />
          </span>
        </HeadlessComboboxButton>

        <HeadlessComboboxOptions
          v-if="allAvailableOptions.length > 0"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          :class="
            large
              ? 'text-lg leading-6 py-4'
              : 'text-base  sm:text-sm leading-6 py-1'
          "
        >
          <HeadlessComboboxOption
            v-for="scenario in filteredOptions"
            :key="scenario[props.labelField]"
            :value="scenario"
            as="template"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active ? 'bg-Primary-400 text-white' : 'text-gray-900',
              ]"
            >
              <span :class="['block truncate', selected && 'font-semibold']">
                {{ scenario[props.labelField] }}
              </span>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4 text-black-900',
                ]"
              >
                <CheckIcon class="h-5 w-5 text-black-900" aria-hidden="true" />
              </span>
            </li>
          </HeadlessComboboxOption>
        </HeadlessComboboxOptions>
      </div>
    </HeadlessCombobox>
  </div>
</template>

<script setup>
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  heading: {
    type: String,
  },
  allAvailableOptions: {
    type: Array,
    required: true,
  },
  labelField: {
    type: String,
    default: "_id",
  },
  caption: {
    type: String,
    default: "Select Country",
  },
  large: {
    type: Boolean,
    default: false,
  },
  resetFlag: {
    type: Boolean,
    default: false,
  }
});

const emits = defineEmits(["update:model-value"]);

const selectedOptionInputQuery = ref("");
const filteredOptions = computed(() => {
  if (selectedOptionInputQuery.value === "") {
    return props.allAvailableOptions;
  } else {
    return props.allAvailableOptions.filter((donor) => {
      return donor[props.labelField]
        .toLowerCase()
        .includes(selectedOptionInputQuery.value.toLowerCase());
    });
  }
});
const selectedOption = ref(null);

watch(()=> props.resetFlag, () => {
  selectedOption.value = null
  selectedOptionInputQuery.value = ""
})

</script>

<style lang="scss" scoped></style>
